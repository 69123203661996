import $ from 'jquery';
import 'slick-carousel';

window.onload = function() {
  bindGoTopBtnEvents()
  bindYacModalEvent()
  bindYacHowtoModalEvent()

  $('.js-slick-wrap').slick({
    slidesToShow: 3,
    centerMode: true,
    centerPadding: '60px',
    variableWidth: true,
    arrows: false,
    initialSlide: 1,
    responsive: [{
      breakpoint: 768,
      settings: {
        initialSlide: 0
      }
    }]
  });
  
  $('.js-slick-prev').on('click', function() {
    $('.js-slick-wrap').slick('slickPrev');
  });
  $('.js-slick-next').on('click', function() {
    $('.js-slick-wrap').slick('slickNext');
  });

  $('.js-modal-trigger').on('click', function(){
    var click =  $(this).data('modal');
    // モーダル内画像を設定
    if(profileList[click].imagePath !== ''){
      var modalImageElm = '<img src="'+profileList[click].imagePath+'" alt="">'
    }else{
      var modalImageElm = ''
    }
    $(".js-team-modal-image").html(modalImageElm)
    // モーダル内名前を設定
    if(profileList[click].carrer !== ''){
      var modalNameElm = profileList[click].name+'<br><span>'+profileList[click].carrer+'</span>'
    }else{
      var modalNameElm = profileList[click].name
    }
    $(".js-team-modal-name").html(modalNameElm)

    // モーダル内詳細テキストを設定
    var modalDetailElm = profileList[click].detail
    $(".js-team-modal-detail").html(modalDetailElm)


    $(".js-team-modal-wrap").addClass("is-active")
  })

  $(".js-modal-close").on("click", function(){
    $(".js-team-modal-wrap").removeClass("is-active")
  })

  $(".js-team-modal-bg").on("click", function(){
    $(".js-team-modal-wrap").removeClass("is-active")
  })

}

const profileList = {
  enomoto: {
    imagePath: '/assets/images/common/profile/remi_enomoto.jpg',
    name: '榎本麗美',
    carrer: 'そらビ共同代表 宇宙キャスター／J-SPARCナビゲーター／フリーアナウンサー',
    detail: '宇宙アクセサリーブランド「Uchury（ウチュリー）」代表。星空案内人。宇宙キャスターとして、日本テレビにて「Crew Dragon 宇宙へ」「小惑星探査機 はやぶさ2 地球へ」など多くの宇宙番組を企画し放送。宇宙のライブ映像を使った実況はテレビ史上初の試みであったことから表彰を受ける。また、JAXAや民間企業主催イベント・番組出演のほか、渋谷西武とのコラボイベント「Infinity Space」など、自ら宇宙イベントを企画・主催。現在は、宇宙教育に尽力、“サステナブルな宇宙と地球の未来”を築きたいという想いで活動している。'
  },
  nakajima: {
    imagePath: '/assets/images/common/profile/yumi_nakajima.jpg',
    name: '中島由美',
    carrer: 'そらビ共同代表 JAXA新事業促進部　宇宙イノベーションパートナーシップ（J-SPARC）プロデューサー',
    detail: '経済学部を卒業後、地元金融機関でオンラインシステム更改プロジェクトに従事。その後1年間の米国留学を経て、広告業界にキャリアチェンジ。広告制作会社（ビジュアルコンテンツ／デジタルコンテンツ）で、ディレクター・プロデューサーとしてプロジェクト管理を担当し、グラフィック撮影・XRコンテンツ・WEB等の制作を行ってきた。現在は、宇宙関連新規事業創出を目指し活動している。夢は「日本の“宇宙の母”と呼ばれるおばあちゃんになること」。'
  },
  sakamoto: {
    imagePath: '/assets/images/common/profile/aya_sakamoto.jpg',
    name: '坂本彩',
    carrer: '',
    detail: '2010年三井不動産入社。郊外型商業施設のプランニング・リーシングを3年、施設の現場運営（マーケティング・店舗マネジメント等）を担当。2015年より日本橋のエリアマネジメントに従事。エリアマネジメント一般社団法人の立ち上げ・企画・運営や、エリアのプロモーション・PRなどを担当。担当期間中に複数のエリアMICEやオウンドメディア、共創プロジェクトなどを立ち上げ。'
  },
  tsuda: {
    imagePath: '/assets/images/common/profile/yoshio_tsuda.jpg',
    name: '津田賀央',
    carrer: '',
    detail: '2001年より広告会社にて、デジタル領域のプランナーとして様々な広告主の広告企画に携わる。約10年の勤務の末、SONYへ転職。サービスデザイナーとして新規サービスやプロトタイプの企画開発、UXデザインなどを手がける。これからの新しいワークスタイルを考え、2015年に長野県富士見町へ移住。現在はRoute Design合同会社のプロジェクトデザイナーとして、コワーキングスペース『富士見 森のオフィス』を運営しながら、コミュニティー・スペース立ち上げのコンサルティングや、地域商品の企画開発、ブランドのクリエイティブディレクション、イベントの企画運営など、都心と地方地域を行き来しながら様々なプロジェクトに携わっている。星空の綺麗な八ヶ岳で星空観望ツアーを開くのが夢。'
  },
  hashimoto: {
    imagePath: '/assets/images/common/profile/takashi_hashimoto.png',
    name: '橋本貴志',
    carrer: '',
    detail: '広告業界にてコンテンツのプロデュースを中心に活動。その経験を活かし現在は企業のDX、デジタルシフト支援に従事している。「技術は人を動かす」をモットーに企業、団体の様々な技術を可視化し、日々デジタルでの表現を模索している。その中で出会った宇宙業界をもっと盛り上げたいと考え、そらビに参加。子供たちが宇宙と関わるのが当たり前の世界にしたい。'
  },
  tanaka: {
    imagePath: '',
    name: '田中直人',
    carrer: 'エンジニア',
    detail: '大学で工学・物理学を履修後、大学院にて非言語コミュニケーションと人間の個性を研究。2018年よりデジタル広告制作会社に勤務。好きな探査機はかぐや(SELENE)。'
  },
  morita: {
    imagePath: '/assets/images/common/profile/kengo_morita.jpg',
    name: '森田健吾',
    carrer: '学生リーダー',
    detail: '通称もりけん。<br>高校時代から360度カメラで撮影した星空タイムラプス映像をNUKEMIRI VRとして公開している。趣味はドローンを用いた空撮。慶應義塾大学在学。'
  },
  koyama: {
    imagePath: '/assets/images/common/profile/tomoko_koyama.jpg',
    name: '小山智子',
    carrer: 'BASE Q　広報・マーケティング',
    detail: 'オーストラリアの高校留学後、大学在学中よりエンターテインメント業界でグッズ開発に携わる。お台場の商業施設運営を経て、2011年より三井不動産株式会社日本橋街づくり推進部。6年間のイベントプロモーションに従事したのち、2018年東京ミッドタウン日比谷＜BASE Q＞のオープンと同時に運営メンバーに着任。ラグビー鑑賞とISSきぼうの観察がライフワーク。自身の子供が無類の宇宙好きで一緒に宇宙を勉強中。'
  },
  ebata: {
    imagePath: '',
    name: '恵畑翔太郎',
    carrer: '学生チームメンバー',
    detail: '慶應義塾大学2年　高校時代はNZ留学後、認知言語学研究に勤しみ、現在は大学で国際安全保障学を学ぶ。趣味はドローン空撮、カメラ、ギター。'
  },
  shimizu: {
    imagePath: '',
    name: '清水駿太',
    carrer: '学生チームメンバー',
    detail: '東京大学理科一類2年生　天文学科もしくは航空宇宙工学科に進学して宇宙に関わる仕事につきたい。高校時代は森田・青山と同じ天文部に所属。今は東京大学体操部に所属。'
  },
  aoyama: {
    imagePath: '/assets/images/common/profile/taichi_aoyama.jpg',
    name: '青山大智',
    carrer: '学生チームメンバー',
    detail: '中学・高校時代に天文部に所属。一橋大学在学。'
  }
}

function bindGoTopBtnEvents() {
  var goTopBtnElms = document.getElementsByClassName('js-goTop-btn')
  for(var i=0; i<goTopBtnElms.length; i++){
    goTopBtnElms[i].addEventListener('click', function(){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    })
  }
}

function bindYacModalEvent() {
  var yacModalOpenBtnElm = document.getElementById('js_yac-modal_open')
  var yacModalCloseBgElm = document.getElementById('js_yac-modal_close-bg')
  var yacModalCloseBtnElm = document.getElementById('js_yac-modal_close-btn')
  var yacModalElm = document.getElementById('js_yac-modal')
  if(yacModalElm){
    if(typeof yacModalOpenBtnElm !== 'undefined'){
      yacModalOpenBtnElm.addEventListener('click', function() {
        yacModalElm.classList.add('is-open')
      })
    }
    if(typeof yacModalCloseBgElm !== 'undefined'){
      yacModalCloseBgElm.addEventListener('click', function() {
        yacModalElm.classList.remove('is-open')
      })
    }
    if(typeof yacModalCloseBtnElm !== 'undefined'){
      yacModalCloseBtnElm.addEventListener('click', function() {
        yacModalElm.classList.remove('is-open')
      })
    }
  }
}
function bindYacHowtoModalEvent() {
  var yacModalOpenBtnElm = document.getElementById('js_yac-howtomodal_open')
  var yacModalCloseBgElm = document.getElementById('js_yac-howtomodal_close-bg')
  var yacModalCloseBtnElm = document.getElementById('js_yac-howtomodal_close-btn')
  var yacModalElm = document.getElementById('js_yac-howtomodal')
  if(yacModalElm){
    if(typeof yacModalOpenBtnElm !== 'undefined'){
      yacModalOpenBtnElm.addEventListener('click', function() {
        yacModalElm.classList.add('is-open')
      })
    }
    if(typeof yacModalCloseBgElm !== 'undefined'){
      yacModalCloseBgElm.addEventListener('click', function() {
        yacModalElm.classList.remove('is-open')
      })
    }
    if(typeof yacModalCloseBtnElm !== 'undefined'){
      yacModalCloseBtnElm.addEventListener('click', function() {
        yacModalElm.classList.remove('is-open')
      })
    }
  }
}